import { useEffect } from 'react';
import './Header.css';
import logoc from './logoc.svg';

function Header() {
    useEffect(() => {
        const header = document.getElementById('Header-Logo');

        setTimeout(() => {
            header.style.transform = 'translateY(0)';
            header.style.maxHeight = '150px';
        }, 3000);

        setTimeout(() => {
            document.getElementById('Animated-Border-Left').style.width = '50%';
            document.getElementById('Animated-Border-Right').style.width = '50%';
        }, 5000);

        setTimeout(() => {
            document.getElementById('Animated-Border-Left').style.opacity = 0.15;
            document.getElementById('Animated-Border-Right').style.opacity = 0.15;
        }, 6000);

    }, []);

	return (
        <div className="Header">
            <img src={logoc} id="Header-Logo" alt="logo" />
            <section id="Animated-Border">
                <div id="Animated-Border-Left"></div>
                <div id="Animated-Border-Right"></div>
            </section>
        </div>
    )
		
}

export default Header;
