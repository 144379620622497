import './App.css';
import Desktop from './Desktop/Desktop.js';
import Mobile from './Mobile/Mobile';

function App() {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const deviceType = isMobile ? 'Mobile' : 'Desktop';

	return deviceType === 'Mobile' ?
		<Mobile/>
		:
		<Desktop/>
		
}

export default App;
