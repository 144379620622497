import './AIFocus.css';
import Lottie from 'react-lottie';
import AI from './AI.json';


function AIFocus() {
   	return (
        <div id="AIFocus">
             <h3>Our Focus on AI</h3>
            <div class="content-container">
                <div class="text-container">
                    <p>At Mimir's Lab, we understand the importance of artificial intelligence (AI) in today's technology landscape. That's why we incorporate AI into our development processes whenever possible, helping our clients to stay ahead of the curve and achieve their goals.</p>
                    <p>Our team of experts is proficient in various AI technologies, such as machine learning, natural language processing, and computer vision. We use these technologies to create innovative solutions, automate processes, and enhance user experiences, allowing businesses to harness the full potential of AI and gain a competitive advantage.</p>
                    <p>By integrating AI into web and mobile applications, we enable our clients to unlock valuable insights, streamline operations, and deliver personalized experiences to their customers. Our commitment to staying up-to-date with the latest advancements in AI ensures that we provide cutting-edge, future-proof solutions that empower businesses to grow and succeed.</p>
                </div>
                <div class="image-container">
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: AI,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    height={320}
                    width={400}
                    />
                </div>
            </div>
        </div>
    )
		
}

export default AIFocus;
