import { useEffect } from 'react';
import AIFocus from './AIFocus/AIFocus';
import Approach from './Approach/Approach';
import Contact from '../Shared/Contact/Contact';
import './Desktop.css';
import Expertise from './Expertise/Expertise';
import Header from '../Shared/Header/Header';
import Introduction from './Introduction/Introduction';
import Navbar from './Navbar/Navbar';
import Services from './Services/Services';

function Desktop() {
    useEffect(() => {
        const content = document.getElementById('content');
        const bg = document.getElementById('bg');
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
        
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });

        setTimeout(() => {
            bg.style.opacity = 0;
        }, 3000);

        setTimeout(() => {
            content.style.display = 'initial';
        }, 6300);
    }, []);

	return (
        <div className="Desktop">
            <div id="bg"></div>
            <Navbar/>
            <Header/>
            <Introduction/>
            <section id="content">
                <Services/>
                <Expertise/>
                <AIFocus/>
                <Approach/>
                <Contact/>
            </section>
        </div>
    )
		
}

export default Desktop;
