import './Services.css';
import Lottie from 'react-lottie';
import AppDev from './AppDev.json';
import MimirUIUX from './MimirUIUX.json';
import Planning from './Planning.json';
import MimirStats from './transformation.json';
import Programmer from './maintanance.json';

function Services() {
    const lottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',

      }
    };

	return (
        <div id="Services">
            <h3>Our Services</h3>
            <p>
            At Mimir's Lab, we are committed to providing end-to-end solutions for all your web and mobile app requirements. From ideation to launch and ongoing support, we are equipped to handle every stage of the development process. Our services include:
            </p>
            <ul>
                <li>
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: MimirUIUX,
                        }} 
                        width={'75%'}
                        height={'75%'}
                    />
                    <p>
                    UI/UX Design
                    </p>
                </li>
                <li>
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: AppDev,
                        }} 
                        width={'75%'}
                        height={'75%'}
                    />
                    <p>
                    Custom Web and Mobile App Development
                    </p>
                </li>
                <li>
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: MimirStats,
                        }} 
                        width={'75%'}
                        height={'75%'}
                    />
                    <p>
                    Product Strategy and Planning
                    </p>
                </li>
                <li>
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: Planning,
                        }} 
                        width={'75%'}
                        height={'75%'}
                    />
                    <p>
                    Quality Assurance
                    </p>
                </li>
                <li>
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: Programmer,
                        }} 
                        width={'75%'}
                        height={'75%'}
                    />
                    <p>
                    Ongoing Maintenance
                    </p>
                </li>
            </ul>
        </div>
    )
		
}

export default Services;
