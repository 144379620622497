import './Introduction.css';
import Lottie from 'react-lottie';
import IntroS1 from './MimirS1.json';
import IntroS2 from './MimirS2.json';
// import IntroS3 from './MimirS3.json';
import { useEffect } from 'react';

function Introduction() {
    const lottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    useEffect(() => {
        const section1 = document.getElementById('section-1');
        const section2 = document.getElementById('section-2');
        // const section3 = document.getElementById('section-3');

        setTimeout(() => {
            section1.childNodes[0].style.transform = 'translateX(0)';
            section1.childNodes[1].style.transform = 'translateX(0)';
        }, 3000);

        setTimeout(() => {
            section1.childNodes[0].style.opacity = 1;
            section1.childNodes[1].style.opacity = 1;
        }, 3300);

        setTimeout(() => {
            section2.childNodes[0].style.transform = 'translateY(0)';
            section2.childNodes[1].style.transform = 'translateY(0)';
        }, 4000);

        setTimeout(() => {
            section2.childNodes[0].style.opacity = 1;
            section2.childNodes[1].style.opacity = 1;
        }, 4500);

        // setTimeout(() => {
        //     section3.childNodes[0].style.transform = 'translateY(0)';
        //     section3.childNodes[1].style.transform = 'translateY(0)';
        // }, 4300);

        // setTimeout(() => {
        //     section3.childNodes[0].style.opacity = 1;
        //     section3.childNodes[1].style.opacity = 1;
        // }, 5000);

    }, []);

	return (
        <div className="Introduction">
            <section id="section-1">
                <article className="content">
                    <h3>Welcome to Mimir's Lab</h3>
                    <p>
                        At Mimir's Lab, we're passionate about creating innovative web and mobile applications that help our clients succeed. With a focus on the latest technologies and a commitment to staying ahead of the curve, we offer complete solutions for all your development needs.
                    </p>
                </article>
                <article className="support">
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: IntroS1,
                        }} 
                        height={(window.innerHeight - 200) / 2}
                        width={(window.innerHeight - 200) / 2}
                    />
                </article>
            </section>
            <section id="section-2">
                <article className="support">
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: IntroS2,
                        }} 
                        height={(window.innerHeight - 100) / 2}
                        width={(window.innerHeight - 200) / 2}
                    />
                </article>
                <article className="content">
                    <p>Our team of experienced developers and consultants are dedicated to delivering high-quality results that exceed your expectations. We believe that every project is unique and requires a personalized approach, so we work closely with our clients to ensure that we're delivering solutions that meet their specific requirements.</p>
                </article>
            </section>
            {/* <section id="section-3">
                <article className="content">
                    <p>From concept to launch, we're here to help you every step of the way. Whether you're a small startup or a large enterprise, we have the expertise and resources to help you succeed. So if you're looking for a development partner that can help you bring your ideas to life, look no further than Mimir's Lab.</p>
                </article>
                <article className="support">
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: IntroS3,
                        }} 
                        height={(window.innerHeight - 100) / 3}
                        width={(window.innerHeight - 200) / 3}
                    />
                </article>
            </section> */}
        </div>
    )
		
}

export default Introduction;
