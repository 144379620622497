import './Contact.css';

function Contact() {
   	return (
        <div id="Contact">
            <h2>Get in Touch</h2>
            <p>Ready to start your project with Mimir's Lab? Contact us today to schedule a consultation and learn more about how we can help you achieve your goals. Fill out the form below, and we will get back to you as soon as possible.</p>
            <form class="contact-form">
                <input class="form-field" type="text" name="name" placeholder="Your Name" required/>
                <input class="form-field" type="email" name="email" placeholder="Your Email" required/>
                <textarea class="form-field" name="message" rows="5" placeholder="Your Message" required></textarea>
                <button class="submit-btn" type="submit">Send Message</button>
            </form>
        </div>
    )
		
}

export default Contact;
