import './Approach.css';

function Approach() {
   	return (
        <div id="Approach">  
            <h3>Our Approach</h3>
            <p>At Mimir's Lab, we emphasize a customized, comprehensive, and transparent approach for each project, starting from the introductory session to the aftercare phase. We collaborate closely with our clients to ensure that the end product is tailored to their specific requirements, fostering success and satisfaction.</p>
            <div>
                <div class="approach-step">
                    <h3>1. Introductory Session</h3>
                    <p>We kick off with a complimentary introductory session to discuss the project, its demands, and set goals. We also estimate the duration and expenses required for the project launch. This phase includes conferences, phone discussions, and email communication.</p>
                </div>
                <div class="approach-step">
                    <h3>2. Details</h3>
                    <p>In this phase, we sign a contract and a non-disclosure agreement. We engage in gatherings, consultations, and idea sharing, ensuring mutual understanding and agreement. We then finalize the functional and technological requirements, create interactive prototypes, and present a detailed project schedule and pricing.</p>
                </div>
                <div class="approach-step">
                    <h3>3. Handover</h3>
                    <p>After signing the development contract, we execute the project and provide progress updates. We conduct preliminary testing, followed by comprehensive testing with additional personnel. Finally, we roll out the project with all its bells and whistles.</p>
                </div>
                <div class="approach-step">
                    <h3>4. Aftercare</h3>
                    <p>Based on the project type, we offer optional aftercare services, including supervision, assistance, upkeep, and modifications. We monitor system performance, provide prompt support, and ensure adaptability and scalability for changes as needed.</p>
                </div>
            </div>
        </div>
    )
		
}

export default Approach;
