import './MobileIntroduction.css';
import Lottie from 'react-lottie';
import IntroS1 from './MimirS1.json';
import IntroS2 from './MimirS2.json';
// import IntroS3 from './MimirS3.json';
import { useEffect } from 'react';

function MobileIntroduction() {
    const lottieOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    useEffect(() => {
        const section1 = document.getElementById('m-section-1');
        const section2 = document.getElementById('m-section-2');
        // const section3 = document.getElementById('section-3');

        setTimeout(() => {
            section1.childNodes[0].style.transform = 'translateX(0)';
            section1.childNodes[1].style.transform = 'translateX(0)';
        }, 3000);

        setTimeout(() => {
            section1.childNodes[0].style.opacity = 1;
            section1.childNodes[1].style.opacity = 1;
        }, 3300);


        setTimeout(() => {
            section2.childNodes[0].style.opacity = 1;
            section2.childNodes[1].style.opacity = 1;
        }, 4500);
    }, []);

	return (
        <div id="Mobile-Introduction">
            <section id="m-section-1">
                <article className="m-content">
                    <h3>Welcome to Mimir's Lab</h3>
                    <p>
                        At Mimir's Lab, we're passionate about creating innovative web and mobile applications that help our clients succeed. With a focus on the latest technologies and a commitment to staying ahead of the curve, we offer complete solutions for all your development needs.
                    </p>
                </article>
                <article className="m-support">
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: IntroS1,
                        }} 
                        height={250}
                        width={'100%'}
                    />
                </article>
            </section>
            <section id="m-section-2">
                <article className="m-content">
                    <p>Our team of experienced developers and consultants are dedicated to delivering high-quality results that exceed your expectations. We believe that every project is unique and requires a personalized approach, so we work closely with our clients to ensure that we're delivering solutions that meet their specific requirements.</p>
                </article>
                <article className="m-support">
                    <Lottie options={{ 
                        ...lottieOptions, 
                        animationData: IntroS2,
                        }} 
                        height={250}
                        width={'100%'}
                    />
                </article>
            </section>
        </div>
    )
		
}

export default MobileIntroduction;
