import './Expertise.css';

function Expertise() {
   	return (
        <div id="Expertise">
             <h3>Our Expertise</h3>
        <p>Our diverse team of seasoned developers and consultants boasts an extensive skillset in various technologies, including AI and machine learning. Our commitment to continuous learning and adaptation ensures that we remain at the forefront of industry advancements, empowering us to deliver top-notch solutions for our clients.</p>
        <div class="skills-container">
            <div class="skill-card">
                <h3>Latest Front-end Technologies</h3>
                <p>Our team stays up-to-date with the latest front-end technologies to deliver cutting-edge, user-centric web applications. We expertly utilize modern frameworks, libraries, and tools to create visually stunning, high-performance, and accessible solutions that cater to the needs of our clients and their users.</p>
            </div>
            <div class="skill-card">
                <h3>Stable & Reliable Back-end Technologies</h3>
                <p>Our developers are well-versed in stable and reliable back-end technologies that ensure the smooth functioning of web applications. We leverage the power of server-side languages and frameworks to create secure, scalable, and high-performance back-end systems that support seamless user experiences and meet our clients' business objectives.</p>
            </div>
            <div class="skill-card">
                <h3>API Integrations</h3>
                <p>Our team is proficient in API integrations, enabling seamless communication between different software systems and services. We design and develop custom APIs, as well as integrate third-party APIs such as payment gateways, social media platforms, and other services, to enhance the functionality of web applications, streamline workflows, and improve user experiences. Our expertise ensures secure, efficient, and maintainable API integrations that align with our clients' specific requirements.</p>
            </div>
            <div class="skill-card">
                <h3>Artificial Intelligence</h3>
                <p>We harness the power of AI and machine learning to develop intelligent, data-driven applications that deliver advanced capabilities and competitive advantages to our clients.</p>
            </div>
            <div class="skill-card">
                <h3>UI/UX Design</h3>
                <p>Our designers are dedicated to creating intuitive, visually appealing user interfaces that ensure seamless user experiences across all platforms.</p>
            </div>
            <div class="skill-card">
                <h3>Machine Learning</h3>
                <p>We specialize in integrating machine learning algorithms and models into our solutions, allowing our clients to benefit from data-driven insights and automation.</p>
            </div>
        </div>
        </div>
    )
		
}

export default Expertise;
