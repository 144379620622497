
import { useEffect } from 'react';
import './Navbar.css';

function Navbar() {
    useEffect(() => {
        const navbar = document.getElementById('Navbar');

        setTimeout(() => {
            navbar.style.opacity = 1;
        }, 6000);
        
        window.addEventListener('scroll', () => {
            if (window.scrollY > 150) {
                navbar.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            } else {
                navbar.style.backgroundColor = 'transparent';
            }
        });
    }, []);

   	return (
        <div id="Navbar">
            <a href="#Services">Services</a>
            <a href="#Expertise">Expertise</a>
            <a href="#AIFocus">AI Focus</a>
            <a href="#Header-Logo" style={{ flex: 1 }}> </a>
            <a href="#Approach">Approach</a>
            <a href="#Contact">Contact</a>
        </div>
    )
		
}

export default Navbar;
