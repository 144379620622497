import './Mobile.css';
import Header from '../Shared/Header/Header';
import Introduction from './Introduction/MobileIntroduction';

function Mobile() {
   	return (
        <div id="Mobile">
            <Header/>
            <Introduction/>
            <div id="content">

            </div>
        </div>
    )
		
}

export default Mobile;
